import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Auth from "../services/Auth";
import logo from "../assets/logo-white.png"; // import your logo

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = Auth.isAuthenticated();
  const userName = Auth.getName();
  const isSuperAdmin = Auth.isSuperAdmin();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleMyAccount = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    // Call the logout function and remove the token from storage
    Auth.logout();

    // Redirect the user to the /signin page
    navigate("/signin");
  };

  return (
    <AppBar
      position="static"
      style={{ position: "sticky", zIndex: "5", top: "0px", minHeight: "71px" }}
    >
      <Toolbar>
        <Grid container alignItems="center">
          {/* Left part of the Navbar */}
          <Grid item xs>
            {/* MenuIcon for mobile view */}
            <Hidden mdUp>
              <IconButton
                edge="end"
                color="inherit"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              <Button color="inherit" component={RouterLink} to="/">
                Dashboard
              </Button>
              {/* Display links for authenticated users */}
              {isAuthenticated && (
                <>
                  {/* <Button component={RouterLink} to="/dashboard" color="inherit">
                    Dashboard
                  </Button> */}
                  {!isSuperAdmin && (
                    <>
                      <Button
                        component={RouterLink}
                        to="/activities"
                        color="inherit"
                      >
                        Activities
                      </Button>
                      <Button
                        component={RouterLink}
                        to="/packages"
                        color="inherit"
                      >
                        Packages
                      </Button>
                      <Button
                        component={RouterLink}
                        to="/tasks"
                        color="inherit"
                      >
                        Open Tasks
                      </Button>
                      <Button
                        component={RouterLink}
                        to="/invoices"
                        color="inherit"
                      >
                        Invoices
                      </Button>
                    </>
                  )}
                  {/* <Button component={RouterLink} to="/users" color="inherit">
                    Users
                  </Button> */}
                  {/* Display a special RouterLink for super-admin users */}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/companies"
                      color="inherit"
                    >
                      Companies
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/allusers"
                      color="inherit"
                    >
                      Users
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/allpackages"
                      color="inherit"
                    >
                      Packages
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/alltasks"
                      color="inherit"
                    >
                      Tasks
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/timelogs"
                      color="inherit"
                    >
                      Time Logs
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/allinvoices"
                      color="inherit"
                    >
                      Invoices
                    </Button>
                  )}
                  {isSuperAdmin && (
                    <Button
                      component={RouterLink}
                      to="/allpayments"
                      color="inherit"
                    >
                      Payments
                    </Button>
                  )}
                </>
              )}
            </Hidden>
          </Grid>

          {/* Centered logo */}
          <Grid
            item
            xs={true}
            style={{
              justifyContent: "center",
              minHeight: "71px",
              flex: "none",
            }}
          >
            <Hidden smDown>
              {location.pathname !== "/" &&
                location.pathname !== "/dashboard" && (
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    component={RouterLink}
                    to="/"
                  >
                    <img src={logo} alt="Logo" height="55" />
                  </IconButton>
                )}
            </Hidden>
          </Grid>

          {/* Right part of the Navbar */}
          <Grid item xs container justifyContent="flex-end">
            {!isAuthenticated && (
              <>
                {/* Add other links/components as necessary */}
                <Button color="inherit" component={RouterLink} to="/signin">
                  Sign In
                </Button>
                <Button color="inherit" component={RouterLink} to="/signup">
                  Sign Up
                </Button>
              </>
            )}
            {isAuthenticated && (
              <div>
                <Button color="inherit" onClick={handleClick} id="basic-button">
                  {userName}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleMyAccount}>My account</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
            {/* Add more navigation links as needed */}
          </Grid>
        </Grid>
      </Toolbar>
      {/* Mobile Drawer */}
      <Hidden mdUp>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List>
            <>
              <ListItem component={RouterLink} to="/">
                <ListItemText
                  primary="Dashboard"
                  onClick={toggleDrawer(false)}
                />
              </ListItem>
              {isAuthenticated && !isSuperAdmin && (
                <>
                  <ListItem
                    component={RouterLink}
                    to="/activities"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Activities" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/packages"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Packages" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/tasks"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Open Tasks" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/invoices"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Invoices" />
                  </ListItem>
                </>
              )}
              {isAuthenticated && isSuperAdmin && (
                <>
                  <ListItem
                    component={RouterLink}
                    to="/companies"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Companies" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/allusers"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Users" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/allpackages"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Packages" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/timelogs"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Time Logs" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/alltasks"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Tasks" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/allinvoices"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Invoices" />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    to="/allpayments"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemText primary="Payments" />
                  </ListItem>
                </>
              )}
            </>
            {/* Add other links/buttons as needed */}
          </List>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

export default Navbar;
