// src/components/PackageDetail.js

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import ClientApi from "../services/ClientApi";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";

const PackageDetail = () => {
  const [packageDetail, setPackageDetail] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    const fetchPackageDetail = async () => {
      try {
        const response = await ClientApi.get(`/packages/${id}`);
        setPackageDetail(response.data);
      } catch (err) {
        console.error("Error fetching package detail:", err);
      }
    };
    fetchPackageDetail();
  }, [id]);

  if (!packageDetail) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom>
        {packageDetail.name}
      </Typography>
      <Typography variant="h5">Price: ${packageDetail.price}</Typography>
      <Typography variant="body1">
        Description: {packageDetail.description}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/dashboard/checkout"
      >
        Buy Package
      </Button>
    </Container>
  );
};

export default PackageDetail;
