import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientApi from "../services/ClientApi";
import ForgotPassword from "./ForgotPassword";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import Link from "@mui/material/Link";
import Auth from "../services/Auth";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [renderForgotPassword, setRenderForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(`Loggin in... ${email} ${password}`);
      await ClientApi.post("/account/login", {
        emailAddress: email,
        password,
      })
        .then((response) => {
          console.log({ response });
          Auth.loggedIn(response.data);
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log({ err }, err.response.data);
          setError(
            err?.response?.data?.message ??
              err?.response?.data ??
              err?.message ??
              err
          );
        });
      // console.log({ response });
      // Auth.loggedIn(response.data);
      // navigate("/dashboard");
    } catch (err) {
      console.log(err);
      setError(
        "Invalid credentials." // Temporarily adding a fake token. You can navigate to Home to see as if you are logged in."
      );
      // Auth.setToken("facke-temp");
    }
  };

  if (renderForgotPassword) return <ForgotPassword />;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        marginTop: "20px",
      }}
    >
      <Container maxWidth="xs">
        <Box my={3} mt={8}>
          <Box my={3}>
            <Typography variant="h4" align="center" gutterBottom>
              Sign In
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="email"
                  label="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  type="password"
                  required
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Sign In
                </Button>
              </Grid>
              <Grid item xs={12}>
                {/* Enhanced Forgot Password Link */}
                <Link
                  href="/forgot-password"
                  underline="none"
                  variant="body2"
                  sx={{ color: "secondary.main" }}
                  onClick={() => setRenderForgotPassword(true)}
                >
                  Forgot Password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default SignIn;
