// src/services/Auth.js

class Auth {
  setToken(token) {
    localStorage.setItem("token", token);
  }

  loggedIn(data) {
    console.log(data);
    this.setToken(data.token);
    localStorage.setItem(
      "user",
      JSON.stringify({
        name: data.name,
        userId: data.userId,
        isAdmin: data.isAdmin,
        isSuperAdmin: data.isSuperAdmin,
      })
    );
    localStorage.setItem("isAdmin", data.isAdmin);
    localStorage.setItem("isSuperAdmin", data.isSuperAdmin);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  removeToken() {
    localStorage.removeItem("token");
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("isSuperAdmin");
  }

  // Check if the user is authenticated
  isAuthenticated() {
    const token = localStorage.getItem("token");
    return !!token;
  }

  getName() {
    return JSON.parse(localStorage.getItem("user"))?.name;
  }

  // Check if the user has the required role
  hasRole(requiredRole) {
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role === requiredRole;
  }

  isAdmin() {
    return localStorage.getItem("isAdmin") === "true";
  }
  isSuperAdmin() {
    return localStorage.getItem("isSuperAdmin") === "true";
  }
}

const authService = new Auth();

export default authService;
