import React, { useState, useEffect } from "react";
import ClientApi from "../services/ClientApi";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await ClientApi.get("/invoices");
        setInvoices(response.data.invoices);
      } catch (err) {
        console.error("Error fetching Invoices:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();
  }, []);

  const handlePdfPreview = (invoiceId) => {
    setInvoices(
      invoices.map((invoice) => {
        if (invoice._id === invoiceId) {
          return { ...invoice, pdfPreviewOpen: !invoice.pdfPreviewOpen };
        }
        return invoice;
      })
    );
  };

  const currenctyOption = (amount) => {
    const digits = amount % 1 != 0 ? 2 : 0;
    return {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    };
  };

  if (loading)
    return (
      <Container>
        <Box my={3}>
          <Typography variant="h3" align="center">
            Invoices
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Invoices
        </Typography>
      </Box>
      <List>
        {invoices.map((invoice) => (
          // <ListItem key={invoice._id}>
          //   <ListItemText primary={invoice.title} secondary={invoice.description} />
          // </ListItem>
          <React.Fragment>
            <Accordion
              style={{ maxWidth: "650px", margin: "auto", marginTop: "8px" }}
              key={invoice._id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={invoice._id}
                id={invoice._id}
                style={{ fontWeight: "bold" }}
              >
                <Typography>{`${invoice.invoiceId} - ${new Date(
                  invoice.transactionDate
                ).toLocaleDateString()} - ${
                  invoice.completeStatus ? "Completed" : "Pending"
                }`}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ whiteSpace: "pre-wrap" }}>
                <p>{invoice.description}</p>
                <p>
                  Amount:{" "}
                  {invoice.price.toLocaleString(
                    "en-US",
                    currenctyOption(invoice.price)
                  )}
                </p>
                <p>
                  Balance:{" "}
                  {invoice.remainingBalance.toLocaleString(
                    "en-US",
                    currenctyOption(invoice.remainingBalance)
                  )}
                </p>
                <p>Selected Payment Method: {invoice.selectedPaymentMethod}</p>
                <Button onClick={() => handlePdfPreview(invoice._id)}>
                  View PDF
                </Button>
                {invoice.payments?.length > 0 && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Payments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {invoice.payments.map((payment) => (
                          <ListItem key={payment._id}>
                            <ListItemText
                              primary={`Payment Date: ${new Date(
                                payment.paymentDate
                              ).toLocaleDateString()}`}
                            />
                            <ListItemText
                              secondary={`Amount: ${
                                payment.method
                              } - ${payment.amount.toLocaleString(
                                "en-US",
                                currenctyOption(payment.amount)
                              )} - ${payment.status}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
              </AccordionDetails>
            </Accordion>
            <Dialog
              open={invoice.pdfPreviewOpen}
              onClose={() => handlePdfPreview(invoice._id)}
              // fullWidth={true}
              maxWidth="xl"
            >
              <DialogTitle>Invoice PDF</DialogTitle>
              <DialogContent>
                <iframe
                  src={invoice.pdfUrl} // Replace with your copied Google Drive embed URL
                  width="640"
                  height="480"
                  allow="autoplay"
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        ))}
      </List>
      {invoices.length == 0 && (
        <Typography variant="h6" align="center">
          No Invoices Found
        </Typography>
      )}
    </Container>
  );
};

export default Invoices;
