// src/components/Checkout.js

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClientApi from "../services/ClientApi";

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const packageItem = location.state?.packageItem;
  const [paymentOption, setPaymentOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!packageItem) {
      navigate("/");
    }
  }, [packageItem, navigate]);

  const handlePurchase = async () => {
    try {
      setIsLoading(true);
      const response = await ClientApi.post("/invoices/purchase", {
        packageId: packageItem._id,
        paymentOption,
      });
      navigate("/confirmation", {
        state: { response: response.data, paymentOption, packageItem },
      });
    } catch (error) {
      setIsLoading(false);
      alert(`We got an error: ${error}`);
    }
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  if (!packageItem) {
    return null; // Render nothing while waiting for the navigation to take effect
  }

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h2" align="center" gutterBottom>
          Checkout
        </Typography>
      </Box>
      <Typography variant="h4" align="center" gutterBottom>
        {packageItem.description}
      </Typography>
      <Typography variant="h5">
        Price: ${packageItem.rate * packageItem.hours}
      </Typography>
      <Typography variant="h5">Hours: {packageItem.hours}</Typography>
      <Typography variant="h5">Hourly Rate: ${packageItem.rate}</Typography>
      <br />
      <br />
      {/* Payment Options */}
      <FormControl component="fieldset" style={{ marginTop: "15px" }}>
        <FormLabel component="legend">
          SysTime Payment Options (coming soon..)
        </FormLabel>
        <RadioGroup
          aria-label="payment-options"
          name="payment-options"
          value={paymentOption}
          onChange={handlePaymentOptionChange}
        >
          <FormControlLabel
            value="Systime-ACH"
            control={<Radio disabled />}
            label="Direct Debit (ACH / Bank Transfer)"
            title="Coming soon..."
          />
          <FormControlLabel
            value="CC"
            control={<Radio disabled />}
            label="Credit Card"
            title="Coming soon..."
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        style={{ marginTop: "15px", marginLeft: "75px" }}
      >
        <FormLabel component="legend">
          Manual Payment Options (Takes longer to post - You will be notified
          when it's verified.)
        </FormLabel>
        <RadioGroup
          aria-label="payment-options"
          name="payment-options"
          value={paymentOption}
          onChange={handlePaymentOptionChange}
        >
          <FormControlLabel
            value="ACH"
            control={<Radio />}
            label="Send an ACH Bank Transfer"
          />
          <FormControlLabel
            value="QuickPay"
            control={<Radio />}
            label="Send a QuickPay/Zelle"
          />
          <FormControlLabel
            value="Check"
            control={<Radio />}
            label="Send a Check by Mail"
          />
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      <br />
      {/* Payment Instructions */}
      {paymentOption === "ACH" && (
        <Typography>
          To pay via ACH, please send the payment using the following bank
          details:
          <br />
          Company Name: SysDone LLC
          <br />
          Routing Number: 226072511
          <br />
          Checking Account Number: 0050441492
        </Typography>
      )}
      {paymentOption === "QuickPay" && (
        <Typography>
          To pay via QuickPay/Zelle, please send the payment to the following
          email address:
          <br />
          aron@sysdone.com
        </Typography>
      )}
      {paymentOption === "Check" && (
        <Typography>
          To pay by check, please write it payable to "SysDone LLC" and mail it
          to:
          <br />
          SysDone
          <br />
          11 Taft Ln Apt 3
          <br />
          Spring Valley, NY 10977
        </Typography>
      )}
      <div>
        {/* <Button
          variant="contained"
          style={{ marginTop: "15px", marginBottom: "25px" }}
          color="primary"
          disabled={isLoading}
          onClick={handlePurchase}
          >
          Purchase Package
        </Button> */}
        <LoadingButton
          style={{ marginTop: "15px", marginBottom: "25px" }}
          onClick={handlePurchase}
          endIcon={<SaveIcon />}
          loading={isLoading}
          loadingPosition="end"
          variant="contained"
        >
          <span>Purchase Package</span>
        </LoadingButton>
      </div>
    </Container>
  );
};

export default Checkout;
