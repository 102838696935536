import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddIcon from "@mui/icons-material/Add";
import ClientApi from "../services/ClientApi";

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);
  const [form, setForm] = useState({
    _id: "",
    companyName: "",
    billingName: "",
    billingEmail: "",
    companyAddress: "",
    companyPhone: "",
    website: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [emailType, setEmailType] = useState(null);

  useEffect(() => {
    // Fetch companies from your data source
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    fetchCompanies();
  }, [refresh]);

  const handleOpenConfirmDialog = (company, type) => {
    setSelectedCompany(company);
    setEmailType(type);
    setOpenConfirmDialog(true);
  };
  
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedCompany(null);
    setEmailType(null);
  };

  const handleConfirmSendEmail = async () => {
    // Logic to send the "Low Hour Balance" email
    // runninglow or negative
    console.log(`Sending ${emailType} email to ${selectedCompany.companyName}`);
    const response = await ClientApi.put(
      `/superadmin/companies/email/${emailType}/${selectedCompany._id}`
    );
    setOpenConfirmDialog(false);
  };

  const handleSendLowBalanceEmail = (companyId) => {
    alert("Email sent..");
  };

  const handleDeleteModalOpen = (companyId) => {
    setDeleteCompanyId(companyId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteCompanyId(null);
    setErrorMessage(null);
  };

  const handleClickOpen = (item) => {
    if (item) {
      setForm(item);
    } else {
      setForm({
        _id: "",
        companyName: "",
        billingName: "",
        billingEmail: "",
        companyAddress: "",
        companyPhone: "",
        website: "",
      });
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (form._id) {
      ClientApi.put(`/superadmin/companies/${form._id}`, form)
        .then((response) => {
          setCompanies((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      ClientApi.post("/superadmin/companies", form)
        .then((response) => {
          setCompanies((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
  };

  const handleDelete = () => {
    ClientApi.delete(`/superadmin/companies/${deleteCompanyId}`)
      .then(() => {
        setCompanies((prev) =>
          prev.filter((item) => item._id !== deleteCompanyId)
        );
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = !search
    ? companies
    : companies.filter((row) =>
        row.companyName.toLowerCase().includes(search.toLowerCase())
      );

  return (
    <Container maxWidth="sm">
      <Box my={3}>
        <Typography variant="h3" align="center">
          Company List
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => setRefresh(refresh + 1)}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Company Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Hour Balance</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.companyName}</TableCell>
                  <TableCell>
                    {row.hourBalance.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      disabled={true}
                      onClick={() => handleDeleteModalOpen(row._id)}
                    >
                      <Delete />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenConfirmDialog(row, 'runningLow')}
                      aria-label="send-low-balance-email"
                    >
                      <MoreTimeIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenConfirmDialog(row, 'negative')}
                      aria-label="send-negative-balance-email"
                    >
                      <ReportProblemIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit Company" : "Add Company"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Company Id"
            name="companyId"
            value={form._id}
            fullWidth
            disabled={true}
            margin="normal"
          />
          <TextField
            label="Company Name"
            name="companyName"
            value={form.companyName}
            onChange={handleChange}
            fullWidth
            required={true}
            margin="normal"
          />
          <TextField
            label="Billing Name"
            name="billingName"
            value={form.billingName}
            onChange={handleChange}
            required={true}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Billing Email"
            name="billingEmail"
            value={form.billingEmail}
            onChange={handleChange}
            required={true}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Company Address"
            name="companyAddress"
            value={form.companyAddress}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Company Phone"
            name="companyPhone"
            value={form.companyPhone}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Website"
            name="website"
            value={form.website}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this Company?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{"Confirm Send Email"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to send a {emailType} balance email (
            {selectedCompany?.hourBalance} hours) to{" "}
            {selectedCompany?.companyName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSendEmail} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Companies;
