// components/Confirmation.js
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Typography } from "@mui/material";

const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const response = location.state?.response;
  const paymentOption = location.state?.paymentOption;
  const packageItem = location.state?.packageItem;
  console.log(location.state);

  useEffect(() => {
    if (!response) {
      navigate("/");
    }
  }, [response, navigate]);

  if (!response || !packageItem) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h3" align="center" marginTop="15px" gutterBottom>
        Thank you for your purchase!
      </Typography>
      {/* Additional confirmation details */}
      <Typography gutterBottom variant="h5">
        Invoice Information;
      </Typography>
      <Typography gutterBottom>
        Invoice Number: {response.newInvoice.invoiceId}
      </Typography>
      <Typography gutterBottom>
        Package: {response.newInvoice.description}
      </Typography>
      <Typography gutterBottom>
        Your added hours will become active as soon we verify your payment.
      </Typography>
      <Typography gutterBottom>
        Amount To Be Paid: ${packageItem.rate * packageItem.hours}
        {paymentOption && ` - You have choosen to pay via ${paymentOption}.`}
      </Typography>
      {paymentOption === "Check" && (
        <div>
          <Typography gutterBottom>Please mail the check to:</Typography>
          <Typography gutterBottom>SysDone LLC</Typography>
          <Typography gutterBottom>11 Taft Ln</Typography>
          <Typography gutterBottom>Spring Valley NY 10977</Typography>
        </div>
      )}
      {paymentOption === "ACH" && (
        <div>
          <Typography gutterBottom>
            Please send the payment to the following bank account:
          </Typography>
          <Typography gutterBottom>SysDone LLC</Typography>
          <Typography gutterBottom>Northeast Community Bank</Typography>
          <Typography gutterBottom>Routing number: 226072511</Typography>
          <Typography gutterBottom>Account number: 0050441492</Typography>
        </div>
      )}
      {paymentOption === "QuickPay" && (
        <div>
          <Typography gutterBottom>
            Please send the payment to the following QuickPay email address:
          </Typography>
          <Typography gutterBottom>SysDone LLC</Typography>
          <Typography gutterBottom>aron@sysdone.com</Typography>
        </div>
      )}
      <div>
        <iframe
          src={response.newInvoice.pdfUrl} // Replace with your copied Google Drive embed URL
          width="640"
          height="480"
          allow="autoplay"
        />
      </div>
      {/* You can adjust this to the correct property name */}
      {/* You can add more information based on the response object */}
    </Container>
  );
};

export default Confirmation;
