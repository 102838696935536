import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import ClientApi from "../services/ClientApi";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    // Check if token is expired
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Get current time in seconds
      if (decodedToken.exp < currentTime) {
        setLinkExpired(true);
      }
    } catch (error) {
      console.log(error);
      setMessage("Invalid token.");
      setIsError(true);
    }
  }, [token]);

  const onResendLink = async () => {
    console.log("Requesting new link...");
    try {
      await ClientApi.post(`/account/resend-link`, {
        token,
      });
      setMessage("A new link has been sent to your email.");
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong. Please start over.");
      setIsError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("The Passwords must match.");
      setIsError(true);
      return;
    }
    try {
      const response = await ClientApi.post(`/account/reset-password`, {
        newPassword,
        token,
      });
      setMessage(response.data.message);
      setIsError(false);
      setTimeout(() => {
        navigate("/signin"); // Redirect to login after reset
      }, 6000);
    } catch (error) {
      console.log(error);
      setIsError(true);
      if (
        error.response &&
        error.response.data.message ===
          "Token expired. Please request a new link."
      ) {
        setLinkExpired(true);
      } else {
        setMessage("Error resetting password");
      }
    }
  };

  if (linkExpired)
    return (
      <Box mt={5} textAlign="center">
        <Typography variant="h6" gutterBottom>
          Your reset link has expired.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Click to requset a new link:
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onResendLink}
          sx={{ mt: 2 }}
        >
          Get New Link
        </Button>
        {message && (
          <Typography
            variant="body2"
            color={isError ? "error" : "success"} // Conditional color
            fontWeight="bold"
            mt={2}
          >
            {message}
          </Typography>
        )}
      </Box>
    );

  return (
    <Container maxWidth="sm">
      <Box mt={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Set New Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} width="100%">
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Reset Password
          </Button>
        </Box>

        {message && (
          <Typography
            variant="body2"
            color={isError ? "error" : "success"} // Conditional color
            fontWeight="bold"
            mt={2}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default ResetPassword;
