// src/components/SignUp.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientApi from "../services/ClientApi";
import { Container, TextField, Button, Typography, Box } from "@mui/material";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (password.length < 8) {
      setError("The password needs to be at least 8 characters long.");
      return;
    }

    try {
      await ClientApi.post("/account/signup", {
        firstName,
        lastName,
        mobile,
        emailAddress,
        companyName,
        companyAddress,
        website,
        companyPhone,
        projectDescription,
        password,
      })
        .then((response) => {
          navigate("/signin");
        })
        .catch((err) => {
          setError(
            err?.response?.data?.message ??
              err?.response?.data ??
              err?.message ??
              err
          );
        });
    } catch (err) {
      console.log(err);
      setError("Error during registration.");
    }
  };

  return (
    <Container maxWidth="xs">
      <Box my={3}>
        <Box my={3}>
          <Typography variant="h4" align="center" gutterBottom>
            Sign Up
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ borderBottom: 1, borderColor: "grey.300", py: 2 }}>
            <Typography variant="h6" gutterTop>
              Company Info
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              required
              margin="normal"
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Company Address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Company Phone"
              value={companyPhone}
              onChange={(e) => setCompanyPhone(e.target.value)}
            />
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "grey.300", py: 2 }}>
            <Typography variant="h6" gutterTop>
              User Login Info
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="First Name"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Last Name"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              required
              type="email"
              label="Email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              required
              margin="normal"
              type="password"
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              required
              margin="normal"
              type="password"
              label="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "grey.300", py: 2 }}>
            <Typography variant="h6" gutterTop>
              Brief Project Description
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              minRows={3}
              type="text"
              label="Briefly describe your desired project"
              value={projectDescription}
              onChange={(e) => setProjectDescription(e.target.value)}
            />
          </Box>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button fullWidth variant="contained" color="primary" type="submit">
            Sign Up
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SignUp;
