// src/components/OpenTasks.js

import React, { useState, useEffect } from "react";
import ClientApi from "../services/ClientApi";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Container,
  CircularProgress,
  CardActions,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  AlertTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const OpenTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTaskData, setEditTaskData] = useState({
    title: "",
    description: "",
    priority: 10,
    taskId: 0,
  });
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [newTaskData, setNewTaskData] = useState({
    title: "",
    description: "",
    priority: 1,
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await ClientApi.get("/tasks");
        setTasks(response.data.tasks);
      } catch (err) {
        console.error("Error fetching tasks:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, []);

  const handleAddModalOpen = () => setIsAddModalOpen(true);
  const handleAddModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsAddModalOpen(false);
    setNewTaskData({ title: "", description: "", priority: 1 });
    setErrorMessage(null);
  };
  const handleDeleteModalOpen = (taskId) => {
    setDeleteTaskId(taskId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteTaskId(null);
    setErrorMessage(null);
  };
  const handleEditModalOpen = (taskData) => {
    setEditTaskData(taskData);
    setIsEditModalOpen(true);
  };
  const handleEditModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsEditModalOpen(false);
    setErrorMessage(null);
    setEditTaskData({
      title: "",
      description: "",
      priority: 1,
      taskId: 0,
    });
  };

  const handleAddTask = () => {
    const { title, description, priority } = newTaskData;
    const newTask = {
      // _id: Date.now(), // Generate a unique ID
      title,
      description,
      creationDate: new Date().toISOString(),
      priority,
    };
    ClientApi.post("/tasks", newTask)
      .then((res) => {
        console.log(res);
        setTasks([...tasks, res.data]);
        handleAddModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleEditTask = () => {
    const { taskId, title, description, priority } = editTaskData;
    ClientApi.put(`/tasks/${taskId}`, { title, description, priority })
      .then((res) => {
        setTasks((prevTasks) =>
          prevTasks.map((task) => {
            if (task._id === taskId) {
              return res.data;
            }
            return task;
          })
        );
        handleEditModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleDeleteTask = () => {
    ClientApi.delete(`/tasks/${deleteTaskId}`)
      .then(() => {
        setTasks(tasks.filter((task) => task._id !== deleteTaskId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleDescriptionToggle = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task._id === taskId) {
          return {
            ...task,
            isDescriptionExpanded: !task.isDescriptionExpanded,
          };
        }
        return task;
      })
    );
  };

  const handleHistoryToggle = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task._id === taskId) {
          return { ...task, isHistoryExpanded: !task.isHistoryExpanded };
        }
        return task;
      })
    );
  };

  if (loading)
    return (
      <Container>
        <Box my={3}>
          <Typography variant="h3" align="center">
            Open Tasks
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );

  return (
    <Container maxWidth="sm">
      <Box my={3}>
        <Typography variant="h3" align="center">
          Open Tasks
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          style={{ marginBottom: "10px" }}
          onClick={handleAddModalOpen}
        >
          Add Task
        </Button>
        {tasks
          .sort((a, b) => a.priority - b.priority)
          .map((task) => (
            <Card key={task._id} style={{ marginBottom: "26px" }}>
              <CardHeader
                title={task.title}
                style={{ paddingBottom: 1 }}
                action={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() =>
                        handleEditModalOpen({
                          taskId: task._id,
                          title: task.title,
                          description: task.description,
                          priority: task.priority,
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteModalOpen(task._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              />
              <CardContent style={{ paddingTop: 1, paddingBottom: 1 }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ whiteSpace: "pre-wrap", paddingTop: 0 }}
                >
                  {task.isDescriptionExpanded
                    ? task.description
                    : `${task.description.substring(0, 50)}...`}
                  <IconButton
                    style={{ paddingTop: 0 }}
                    onClick={() => handleDescriptionToggle(task._id)}
                  >
                    {task.isDescriptionExpanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Created: {new Date(task.creationDate).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Priority: {task.priority}
                  <IconButton aria-label="edit priority">
                    {/* Add input field or dropdown for priority editing */}
                  </IconButton>
                </Typography>
                {/* Task history */}
                {task.history?.length > 0 && (
                  <React.Fragment>
                    <Divider />
                    {`Task History (${task.history.length})`}
                    <IconButton onClick={() => handleHistoryToggle(task._id)}>
                      {task.isHistoryExpanded ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                    <Collapse
                      in={task.isHistoryExpanded}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {task.history?.map((historyItem, index) => (
                          <ListItem key={index} alignItems="flex-start">
                            <ListItemText
                              primary={
                                <Box>
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    color="textPrimary"
                                  >
                                    {new Date(
                                      historyItem.creationDate
                                    ).toLocaleDateString()}
                                  </Typography>
                                  {" - "}
                                  <Typography
                                    variant="body1"
                                    component="span"
                                    color="textPrimary"
                                  >
                                    By {historyItem.creationUser.firstName}{" "}
                                    {historyItem.creationUser.lastName}
                                  </Typography>
                                </Box>
                              }
                              secondary={
                                <Box mt={1}>
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    color="textSecondary"
                                  >
                                    <strong>
                                      {historyItem.changedField
                                        .charAt(0)
                                        .toUpperCase() +
                                        historyItem.changedField.slice(1)}
                                    </strong>
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    color="textSecondary"
                                  >
                                    <Box component="span" display="block">
                                      <strong>Changed from:</strong> "
                                      {historyItem.oldValue}"
                                    </Box>
                                    <Box component="span" display="block">
                                      <strong>To:</strong> "
                                      {historyItem.newValue}"
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          ))}
      </Box>

      {tasks.length == 0 && (
        <Typography variant="h6" align="center">
          No Open Tasks
        </Typography>
      )}

      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this Task?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button color="error" onClick={handleDeleteTask}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isAddModalOpen} onClose={handleAddModalClose}>
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            style={{ marginTop: "10px" }}
            value={newTaskData.title}
            onChange={(e) =>
              setNewTaskData({ ...newTaskData, title: e.target.value })
            }
          />
          <TextField
            label="Description"
            style={{ marginTop: "10px" }}
            fullWidth
            multiline
            rows={4}
            value={newTaskData.description}
            onChange={(e) =>
              setNewTaskData({ ...newTaskData, description: e.target.value })
            }
          />
          <TextField
            label="Priority"
            style={{ marginTop: "10px" }}
            type="number"
            fullWidth
            value={newTaskData.priority}
            onChange={(e) =>
              setNewTaskData({
                ...newTaskData,
                priority: parseInt(e.target.value),
              })
            }
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleAddModalClose}>Cancel</Button>
          <Button onClick={handleAddTask}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isEditModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            value={editTaskData.title}
            style={{ marginTop: "10px" }}
            onChange={(e) =>
              setEditTaskData({ ...editTaskData, title: e.target.value })
            }
          />
          <TextField
            label="Description"
            fullWidth
            style={{ marginTop: "10px" }}
            multiline
            rows={4}
            value={editTaskData.description}
            onChange={(e) =>
              setEditTaskData({ ...editTaskData, description: e.target.value })
            }
          />
          <TextField
            label="Priority"
            type="number"
            fullWidth
            style={{ marginTop: "10px" }}
            value={editTaskData.priority}
            onChange={(e) =>
              setEditTaskData({
                ...editTaskData,
                priority: parseInt(e.target.value),
              })
            }
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleEditTask}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OpenTasks;
