// src/components/PackageListItem.js

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";

const PackageListItem = ({ packageItem }) => {
  const navigate = useNavigate();

  const handlePurchase = () => {
    navigate(`/checkout`, { state: { packageItem } }); // Replace "id" with the appropriate property name for package ID
  };

  return (
    <Card sx={{ margin: 2 }}>
      <CardContent>
        <Typography variant="h5">{packageItem.description}</Typography>
        <Typography>{packageItem.hours} Hour Package</Typography>
        <Typography>
          Price: $
          {(packageItem.rate * packageItem.hours)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          (${packageItem.rate}
          /hour)
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePurchase}
          // style={{ marginTop: "10px" }}
          // to={`/packages/${packageItem._id}`}
        >
          Purchase
        </Button>
      </CardActions>
    </Card>
  );
};

export default PackageListItem;
