// src/components/RequireAuth.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../services/Auth";

const RequireAuth = ({ children, requiredRole }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      if (!Auth.isAuthenticated()) {
        navigate("/signin", { replace: true });
        return false;
      }

      if (requiredRole == "isAdmin") return Auth.isAdmin();
      if (requiredRole == "isSuperAdmin") return Auth.isSuperAdmin();

      if (requiredRole && !Auth.hasRole(requiredRole)) {
        navigate("/dashboard", { replace: true });
        return false;
      }
      return true;
    };

    checkAuthentication();
  }, [navigate, requiredRole]);

  return <>{children}</>;
};

export default RequireAuth;
