// src/pages/HomePage.js

import React from "react";
import { Link } from "react-router-dom";
import { Typography, Container, Box, Button } from "@mui/material";
import logo from "../assets/systime-logo.png"; // import your logo
import Dashboard from "./Dashboard";
import Auth from "../services/Auth";

const HomePage = () => {
  // Check if the user is logged in
  const isLoggedIn = Auth.isAuthenticated();
  return (
    <>
      {isLoggedIn ? (
        <Dashboard />
      ) : (
        <Container>
          <Box my={3}>
            {/* Logo centered and enlarged */}
            <Box display="flex" justifyContent="center" marginTop={5}>
              <img
                src={logo}
                alt="Logo"
                style={{ maxWidth: "80%", maxHeight: "80px" }}
              />
            </Box>
            <br />
            <br />
            {/* <Typography variant="body1" align="center" paragraph>
              Our platform is designed to make your life easier by providing you
              with the tools and resources you need. We offer a simple,
              user-friendly interface that allows you to manage your tasks,
              collaborate with others, and streamline your daily operations.
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              As you explore our platform, you'll discover how our innovative
              solutions can save you time and effort, allowing you to focus on
              what matters most. Whether you're a seasoned professional or a
              novice, our platform is tailored to meet your unique needs and
              requirements.
            </Typography> */}
            <Box display="flex" justifyContent="center" mt={3}>
              <Box mx={1}>
                <Button
                  component={Link}
                  to="/signin"
                  variant="outlined"
                  color="primary"
                >
                  Sign In
                </Button>
              </Box>
              <Box mx={1}>
                <Button
                  component={Link}
                  to="/signup"
                  variant="contained"
                  color="primary"
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default HomePage;
