// components/Dashboard.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/systime-logo.png";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import ClientApi from "../services/ClientApi";

const Dashboard = () => {
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("Your Company Name");
  const [error, setError] = useState("");
  const [timeBalance, setTimeBalance] = useState(0);
  const [pendingHours, setPendingHours] = useState(0);
  const [recentActivities, setRecentActivities] = useState([]);
  const [pendingActivities, setPendingActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const renderLines = 3;

  const handleAddMoreHours = () => {
    navigate("/packages");
  };

  const handleSeeMoreActivities = () => {
    navigate("/activities");
  };
  const handleSeeInvoices = () => {
    navigate("/invoices");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientApi.get("activities"); // Adjust the API endpoint based on your backend routes
        const { company, allActivities: timeActivities } = response.data;
        setCompanyName(company.companyName);
        var approvedTimeBal = timeActivities
          .filter((ta) => ta.status === "completed")
          .reduce((a, b) => a + b.hours, 0);
        var pendingHours = timeActivities
          .filter(
            (ta) => ta.activityType === "TimeAdd" && ta.status === "pending"
          )
          .reduce((a, b) => a + b.hours, 0);
        setTimeBalance(approvedTimeBal);
        setPendingHours(pendingHours);
        setRecentActivities(
          timeActivities
            .filter((ta) => ta.status === "completed")
            .sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1))
            .slice(0, renderLines)
        );
        setPendingActivities(
          timeActivities
            .filter(
              (ta) => ta.status === "pending" && ta.activityType === "TimeAdd"
            )
            .slice(0, renderLines)
        );
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Error getting data from the server.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      {error && (
        <Typography color="error" align="center" marginBottom="30px">
          {error}
        </Typography>
      )}
      <Box display="flex" justifyContent="center" marginTop={5}>
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "80%", maxHeight: "130px" }}
        />
      </Box>
      {loading ? (
        <Container>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <>
          {/* Company Name */}
          <Typography variant="h4" align="center" marginTop="15px" gutterBottom>
            {companyName}
          </Typography>

          {/* Time Balance */}
          <Box
            display="flex"
            justifyContent="space-around"
            marginTop="15px"
            marginBottom="50px"
          >
            <Card>
              <CardContent>
                <Typography gutterBottom>Time Credit</Typography>
                <Typography variant="h5" component="div">
                  {timeBalance.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}{" "}
                  hours
                </Typography>
              </CardContent>
            </Card>

            {/* Pending Hours */}
            <Card>
              <CardContent>
                <Typography gutterBottom>Payment Pending</Typography>
                <Typography variant="h5" component="div">
                  {pendingHours} hours
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              {/* Recent Activities */}
              <Box marginBottom="30px" position="relative" textAlign="center">
                <Typography variant="h6" gutterBottom>
                  Recent Activities
                </Typography>
                <List>
                  {recentActivities.map((activity, index) => (
                    <ListItem key={index} style={{ textAlign: "center" }}>
                      <ListItemText
                        primary={`${
                          activity.description
                        }${` (${activity.hours} hours)`}`}
                        secondary={`Date: ${new Date(
                          activity.timeStamp
                        ).toDateString()}`}
                      />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="contained"
                  color="info"
                  style={{ margin: "15px" }}
                  onClick={handleSeeMoreActivities}
                >
                  See More
                </Button>
                {/* <Button color="primary" onClick={handleSeeMoreActivities}>
              See More
            </Button> */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              {/* Recent Packages */}
              <Box marginBottom="30px" position="relative" textAlign="center">
                <Typography variant="h6" gutterBottom>
                  Pending Hours
                </Typography>
                <List>
                  {pendingActivities.map((activity, index) => (
                    <ListItem key={index} style={{ textAlign: "center" }}>
                      <ListItemText
                        primary={activity.description} // Replace with the correct property name for activity description
                        secondary={`Date: ${new Date(
                          activity.timeStamp
                        ).toDateString()}`}
                      />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="contained"
                  color="info"
                  style={{ margin: "15px" }}
                  onClick={handleSeeInvoices}
                >
                  See Invoices
                </Button>
                {/* <Button color="primary" onClick={handleSeeMoreActivities}>
              See More
            </Button> */}
              </Box>
            </Grid>
          </Grid>
          {/* Add More Hours */}
          <Box display="flex" justifyContent="center" marginBottom="15px">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "15px" }}
              onClick={handleAddMoreHours}
            >
              Get More Hours
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default Dashboard;
