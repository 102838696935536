// src/services/ClientApi.js

import axios from "axios";
// import { BrowserRouter } from "react-router-dom";
import Auth from "../services/Auth";

const unauthorizedEvent = new Event("unauthorized");

const baseURL =
  process.env.NODE_ENV == "development"
    ? "http://localhost:5000/api/"
    : "/api/"; // Replace with your API base URL

const ClientApi = axios.create({ baseURL });

ClientApi.interceptors.request.use(
  (config) => {
    if (
      config.url !== "/account/login" &&
      config.url !== "/account/signup" &&
      config.url !== "/account/reset-password" &&
      config.url !== "/account/forgot-password" &&
      config.url !== "/account/resend-link"
    ) {
      const token = Auth.getToken();
      if (token) {
        config.headers["jwttoken"] = `${token}`;
      } else throw new Error("Cannot find token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ClientApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Redirect to the login page using the BrowserRouter instance
      console.log("redirecting to sigin");
      Auth.logout();
      window.dispatchEvent(unauthorizedEvent);
      // BrowserRouter.prototype.history.push("/signin");
    } else {
      return Promise.reject(error);
    }
  }
);

// Optionally, you can set up authentication tokens or other configurations here

export default ClientApi;
