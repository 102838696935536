import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ClientApi from "../services/ClientApi";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await ClientApi.get("/users");
        setUsers(response.data.users);
      } catch (error) {
        setError("Error getting the list of Users.");
        console.error({ error });
      }
    };

    fetchUsers();
  }, []);

  return (
    <Container>
      <Typography variant="h3" align="center" marginTop="15px" gutterBottom>
        User List
      </Typography>
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
      <Grid container spacing={2}>
        <List>
          {users.map((user) => (
            <ListItem key={user._id}>
              <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                secondaryTypographyProps={{ component: "span" }}
                secondary={
                  <div>
                    <div>{user.emailAddress}</div>
                    <div>{user.role}</div>
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Container>
  );
};

export default Users;
