import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";

import ClientApi from "../services/ClientApi";

function InvoicesList() {
  const [companies, setCompanies] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [form, setForm] = useState({
    id: "",
    company: "",
    hours: 0,
    price: 0,
    transactionDate: new Date(),
    description: "",
    status: "",
    selectedPaymentMethod: "",
    transactionId: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    const fetchOpenInvoices = async () => {
      const response = await ClientApi.get("/superadmin/allinvoices"); // Replace with your API endpoint
      setInvoices(response.data);
    };

    fetchCompanies();
    fetchOpenInvoices();
  }, [refresh]);

  const handleDeleteModalOpen = (invoiceId) => {
    setDeleteId(invoiceId);
    setSaveLoading(false);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteId(null);
    setErrorMessage(null);
    setSaveLoading(false);
  };

  const handleClickOpen = (item) => {
    setSaveLoading(false);
    if (item) {
      setForm(item);
    } else {
      setForm({
        id: "",
        company: "",
        hours: 0,
        price: 0,
        transactionDate: new Date(),
        description: "",
        status: "",
        selectedPaymentMethod: "",
        transactionId: "",
      });
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setForm((prev) => ({
      ...prev,
      timeStamp: date,
    }));
  };

  const handleSave = () => {
    if (form._id) {
      setSaveLoading(true);
      ClientApi.put(`/superadmin/invocies/${form._id}`, form)
        .then((response) => {
          setInvoices((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          setSaveLoading(false);
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      setSaveLoading(true);
      ClientApi.post("/superadmin/invoices", form)
        .then((response) => {
          setInvoices((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          setSaveLoading(false);
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
  };

  const handleDelete = () => {
    setSaveLoading(true);
    ClientApi.delete(`/superadmin/invoices/${deleteId}`)
      .then(() => {
        setInvoices((prev) => prev.filter((item) => item._id !== deleteId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredData =
    !search && !filter
      ? invoices
      : invoices.filter(
          (row) =>
            (!filter || row.company === filter) &&
            row.description.toLowerCase().includes(search.toLowerCase())
        );

  const currenctyOption = (amount) => {
    const digits = amount % 1 != 0 ? 2 : 0;
    return {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    };
  };

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Invoices List
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          disabled={true}
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => setRefresh(refresh + 1)}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />

      <TextField
        select
        label="Filter by Company"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {companies.map((company) => (
          <MenuItem key={company._id} value={company._id}>
            {company.companyName}
          </MenuItem>
        ))}
      </TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Payment Method
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Transaction Date
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .sort(
                (a, b) =>
                  new Date(b.transactionDate) - new Date(a.transactionDate)
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id}>
                  <TableCell>
                    {companies.find((c) => c._id === row.company)?.companyName}
                  </TableCell>
                  <TableCell>{row.hours}</TableCell>
                  <TableCell>
                    {row.price.toLocaleString(
                      "en-US",
                      currenctyOption(row.price)
                    )}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.selectedPaymentMethod}</TableCell>
                  <TableCell>
                    {new Date(row.transactionDate).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {row.completeStatus ? "Completed" : "Pending"}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={true}
                      onClick={() => handleClickOpen(row)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      disabled={true}
                      onClick={() => handleDeleteModalOpen(row._id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit Payment" : "Add Payment"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Payment Id"
            name="invoiceId"
            value={form._id}
            fullWidth
            disabled={true}
            margin="normal"
          />
          <TextField
            select
            label="Company"
            name="company"
            value={form.company}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Invoice"
            name="invoice"
            value={form.invoice}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {invoices
              .filter(
                (oi) =>
                  oi.company == form.company &&
                  (form._id || oi.remainingBalance > 0)
              )
              .map((invoice) => (
                <MenuItem key={invoice._id} value={invoice._id}>
                  {`${invoice.invoiceId} ($${invoice.remainingBalance})`}
                </MenuItem>
              ))}
          </TextField>
          {/* <TextField
            label="Invoice Id"
            name="invoiceId"
            value={form.invoiceId}
            fullWidth
            disabled={true}
            margin="normal"
          /> */}
          <TextField
            label="Amount"
            name="amount"
            value={form.amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Method"
            name="method"
            value={form.method}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Status"
            name="status"
            value={form.status}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Date & Time"
              value={new Date(form.paymentDate)}
              onChange={handleDateChange}
              renderInput={(props) => (
                <TextField {...props} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>
          <TextField
            label="Description"
            name="description"
            value={form.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="TransactionId"
            name="transactionId"
            value={form.transactionId}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions style={{ marginBottom: "15px", marginRight: "10px" }}>
          <Button
            onClick={handleClose}
            disabled={saveLoading}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            // style={{ marginTop: "15px", marginBottom: "25px" }}
            onClick={handleSave}
            endIcon={<SaveIcon />}
            loading={saveLoading}
            loadingPosition="end"
            variant="contained"
            color="primary"
          >
            <span>Save</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this Payment?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button
            onClick={handleDeleteModalClose}
            variant="contained"
            disabled={saveLoading}
            color="secondary"
          >
            Cancel
          </Button>
          <LoadingButton
            // style={{ marginTop: "15px", marginBottom: "25px" }}
            onClick={handleDelete}
            endIcon={<DeleteIcon />}
            loading={saveLoading}
            loadingPosition="end"
            variant="contained"
            color="error"
          >
            <span>Delete</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default InvoicesList;
