import { useState } from "react";
import { Button, TextField, Container, Typography, Box } from "@mui/material";
import ClientApi from "../services/ClientApi";

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ClientApi.post("/account/forgot-password", {
        emailAddress,
      });
      setMessage(
        "A reset link has been sent to your email. Please follow the instructions in your email."
      );
      setIsError(false);
    } catch (error) {
      setMessage("Error sending reset link. Try again a bit later.");
      setIsError(true); // It's an error
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} width="100%">
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Get Password Reset Email
          </Button>
        </Box>
        {message && (
          <Typography
            variant="body2"
            color={isError ? "error" : "success"} // Conditional color
            fontWeight="bold"
            mt={2}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPassword;
