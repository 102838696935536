// src/App.js

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Routes from "./Routes"; // Import the Routes component

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ position: "relative", minHeight: "100vh" }}>
          <Navbar />
          <main style={{ paddingBottom: "2.5rem" }}>
            <Routes />
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
