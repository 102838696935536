// src/components/Footer.js

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "primary.main",
        padding: "16px 0px 16px 0px",
        bottom: 0,
        position: "absolute",
        width: "100%",
      }}
    >
      <Typography variant="body1" color="white">
        © {new Date().getFullYear()} SysTime. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
