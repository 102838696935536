import React, { useState, useEffect } from "react";
import ClientApi from "../services/ClientApi";
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";

const MyAccountPage = () => {
  const [editMode, setEditMode] = useState(false);
  const [editPasswordMode, setEditPasswordMode] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveNewPasswordError, setSaveNewPasswordError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await ClientApi.get("/account/profile");
        setUserInfo(response.data);
      } catch (err) {
        console.error("Error fetching profile:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };
  const handleEditPasswordToggle = () => {
    setEditPasswordMode(!editPasswordMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSave = () => {
    // Save the user info to the server or state management
    if (
      !userInfo.firstName ||
      !userInfo.lastName ||
      !userInfo.emailAddress ||
      !/^\S+@\S+\.\S+$/.test(userInfo.emailAddress)
    ) {
      return setSaveError(
        "Fill out all fields and ensure the email address is valid."
      );
    }
    ClientApi.put("/account/profile/update", userInfo)
      .then(() => {
        setEditMode(false);
        setSaveError(null);
      })
      .catch((err) => {
        console.log(err);
        setSaveError(
          err?.response?.data?.message ??
            err?.response?.statusMessage ??
            err.message
        );
      });
  };

  const handlePasswordReset = () => {
    // Perform password reset logic here
    console.log(passwords);
    if (
      !passwords.currentPassword ||
      !passwords.newPassword ||
      !passwords.confirmPassword
    )
      return setSaveNewPasswordError("Please fill out all the fields.");
    if (passwords.newPassword != passwords.confirmPassword)
      return setSaveNewPasswordError("Passwords do not match.");
    ClientApi.post("/account/profile/changepassword", {
      currentPassword: passwords.currentPassword,
      newPassword: passwords.newPassword,
    })
      .then(() => {
        setSaveNewPasswordError(null);
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        setEditPasswordMode(false);
      })
      .catch((err) => {
        console.log(err);
        setSaveNewPasswordError(
          err?.response?.data?.message ??
            err?.response?.statusMessage ??
            err.message
        );
      });
  };

  if (loading)
    return (
      <Container>
        <Box my={3}>
          <Typography variant="h3" align="center">
            Profile
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <AccountCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            My Account
          </Typography>
        </Box>
        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                value={userInfo.firstName}
                onChange={handleChange}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="First Name"
                name="lastName"
                value={userInfo.lastName}
                onChange={handleChange}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="emailAddress"
                label="Email Address"
                name="emailAddress"
                value={userInfo.emailAddress}
                onChange={handleChange}
                disabled={!editMode}
              />
            </Grid>
          </Grid>
          {saveError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {saveError}
            </Alert>
          )}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            {editMode ? (
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditToggle}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ padding: 4, marginTop: 8 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
        </Box>
        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="currentPassword"
                disabled={!editPasswordMode}
                label="Current Password"
                name="currentPassword"
                type="password"
                value={passwords.currentPassword}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                disabled={!editPasswordMode}
                value={passwords.newPassword}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                disabled={!editPasswordMode}
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
          {saveNewPasswordError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {saveNewPasswordError}
            </Alert>
          )}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            {editPasswordMode ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handlePasswordReset}
              >
                Reset Password
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditPasswordToggle}
              >
                Change Your Password
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default MyAccountPage;
