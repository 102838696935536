import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Typography,
  FormControlLabel,
} from "@mui/material";
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import { Edit, Delete } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ClientApi from "../services/ClientApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function AllPackages() {
  const emptyForm = {
    _id: "",
    company: "",
    hours: 0,
    rate: 1000,
    description: "",
    startDate: "",
    endDate: "",
  };
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [form, setForm] = useState(emptyForm);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    // Fetch companies from your data source
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    fetchCompanies();

    // Fetch time logs from your data source
    const fetchPackages = async () => {
      const response = await ClientApi.get("/superadmin/packages"); // Replace with your API endpoint
      setData(response.data);
    };

    fetchPackages();
  }, [refresh]);

  const handleDeleteModalOpen = (packageId) => {
    setDeleteId(packageId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteId(null);
    setErrorMessage(null);
  };

  const handleClickOpen = (item) => {
    if (item) {
      setForm(item);
    } else {
      setForm(emptyForm);
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDateChange = (field, date) => {
    setForm((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  const handleSave = () => {
    if (form._id) {
      ClientApi.put(`/superadmin/packages/${form._id}`, form)
        .then((response) => {
          setData((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      ClientApi.post("/superadmin/packages", form)
        .then((response) => {
          setData((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
  };

  const handleDelete = () => {
    ClientApi.delete(`/superadmin/packages/${deleteId}`)
      .then(() => {
        setData((prev) => prev.filter((item) => item._id !== deleteId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(
    (row) =>
      (!filter || row.company === filter) &&
      row.description.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Packages
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => setRefresh(refresh + 1)}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <TextField
        select
        label="Filter by Company"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {companies.map((company) => (
          <MenuItem key={company._id} value={company._id}>
            {company.companyName}
          </MenuItem>
        ))}
      </TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Rate</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Price</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Start Date</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>End Date</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .sort((a, b) => b.rate - a.rate)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.hours}</TableCell>
                  <TableCell>{row.rate}</TableCell>
                  <TableCell>{row.hours * row.rate}</TableCell>
                  <TableCell>
                    {companies.find((c) => c._id === row.company)?.companyName}
                  </TableCell>
                  <TableCell>
                    {row.startDate
                      ? new Date(row.startDate).toLocaleString()
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.endDate ? new Date(row.endDate).toLocaleString() : ""}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      disabled
                      onClick={() => handleDeleteModalOpen(row._id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit Package" : "Add Package"}</DialogTitle>
        <DialogContent>
          <TextField
            label="ID"
            name="_id"
            value={form._id}
            onChange={handleChange}
            fullWidth
            disabled={true}
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={form.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Hours"
            name="hours"
            value={form.hours}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Rate"
            name="rate"
            value={form.rate}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Start Date"
              value={form.startDate ? new Date(form.startDate) : null}
              onChange={(date) => handleDateChange("startDate", date)}
              renderInput={(props) => (
                <TextField {...props} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="End Date"
              value={form.endDate ? new Date(form.endDate) : null}
              onChange={(date) => handleDateChange("endDate", date)}
              renderInput={(props) => (
                <TextField {...props} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>
          <TextField
            select
            label="Company"
            name="company"
            value={form.company}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.companyName}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this Package?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteModalClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AllPackages;
