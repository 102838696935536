// components/Activities.js
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import ClientApi from "../services/ClientApi";

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await ClientApi.get("activities"); // Replace 'activities' with the API endpoint for your activities
        setActivities(response.data.allActivities);
      } catch (error) {
        setError("Error getting the list of Activities.");
        console.error("Error fetching activities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  // ... rest of the Activities component

  return (
    <Container>
      <Typography variant="h3" align="center" marginTop="15px" gutterBottom>
        Activities
      </Typography>
      {error && (
        <Typography color="error" align="center" marginBottom="30px">
          {error}
        </Typography>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container style={{ maxWidth: "920px" }}>
          <Grid item xs={12}>
            <Timeline align="left">
              {activities.map((activity, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(activity.timeStamp).toDateString()}
                      <br />
                      Hour Bal:{" "}
                      {activity.runningHourBalance.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        activity.activityType === "TimeLog"
                          ? "error"
                          : activity.status === "completed"
                          ? "success"
                          : "secondary"
                      }
                    />
                    {index !== activities.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card style={{ minWidth: "175px" }}>
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {activity.activityType} - {activity.description}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          component="p"
                        >
                          {`Hours ${
                            activity.activityType == "TimeLog"
                              ? "used"
                              : "added"
                          }: ${activity.hours}`}
                        </Typography>
                        {activity.activityType === "TimeAdd" &&
                          activity.status == "pending" && (
                            <Chip
                              label={`${activity.status
                                .charAt(0)
                                .toUpperCase()}${activity.status.slice(1)}`}
                              color={"secondary"}
                              variant="outlined"
                              style={{ marginTop: "8px" }}
                            />
                          )}
                      </CardContent>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      )}
      {activities.length == 0 && (
        <Typography variant="h6" align="center">
          No Activities
        </Typography>
      )}
    </Container>
  );
};

export default Activities;
