// src/components/PackageList.js

import React, { useState, useEffect } from "react";
import ClientApi from "../services/ClientApi";
import PackageListItem from "./PackageListItem";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";

const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [customPackages, setCustomPackage] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPackages = async () => {
    try {
      const response = await ClientApi.get("/packages");
      setPackages(response.data.packages.filter((p) => p.company == null));
      setCustomPackage(response.data.packages.filter((p) => p.company != null));
    } catch (err) {
      setError("Error getting the list of Packages.");
      console.error("Error fetching packages:", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <Container>
      {error && (
        <p style={{ color: "red", textAlign: "center", marginBottom: "30px" }}>
          {error}
        </p>
      )}
      <div
        style={{
          opacity: customPackages && customPackages.length > 0 ? 0.7 : 1,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: "50px" }}
          gutterBottom
        >
          {`${customPackages.length > 0 ? "Standard " : ""}Package Options`}
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {packages
              .sort((a, b) => a.hours - b.hours)
              .map((packageItem) => (
                <Grid key={packageItem._id} item xs={12} sm={6} md={4} lg={4}>
                  <PackageListItem packageItem={packageItem} />
                </Grid>
              ))}
          </Grid>
        )}
      </div>
      {customPackages && customPackages.length > 0 && (
        <div>
          {/* <Divider style={{ margin: "25px" }} /> */}
          <Typography
            variant="h4"
            align="center"
            style={{ marginTop: "50px" }}
            gutterBottom
          >
            {`${customPackages[0]?.company?.companyName}'s Custom Package Options`}
          </Typography>
          <Grid container spacing={2}>
            {customPackages
              .sort((a, b) => a.hours - b.hours)
              .map((packageItem) => (
                <Grid key={packageItem._id} item xs={12} sm={6} md={4} lg={4}>
                  <PackageListItem packageItem={packageItem} />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default PackageList;
